const Constants = {
    Sites: {
        Site: 'studentrecord',
        LogicAppUrl: "https://prod-11.northeurope.logic.azure.com:443/workflows/ad2d4de22f3044b097900d3c26308e1c/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=6UIBxem9qYkO5SbwEKVm0RV1g05X-EEBV7xnZMTFYeM"
    },
    Strings: {
        ErrorContactEmail: 'certificados@esade.edu'
    }
}

export default Constants;