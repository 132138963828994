import axios from 'axios'
import Constants from './Constants';

class API {
    public fetchDoc = async (pParametro: string) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }

        const data = JSON.stringify({
            id: pParametro,
            site: Constants.Sites.Site,
        })

        try {
            let response = axios.post(`${Constants.Sites.LogicAppUrl}`, data, {
                responseType: 'blob',
                headers: headers,
            });

            return response;
        } catch(e) {
            throw e;
        }
        //     .then(async (response) => {
        //     //Create a Blob from the PDF Stream
        //     let blob = new window.Blob([response.data], {
        //         type: 'application/pdf',
        //     })

        //     //Open the URL on new Window
        //     // window.open(fileURL);
        // })
    }
}

export default API;