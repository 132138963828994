import { useCallback, useEffect, useMemo, useState } from 'react'
import './App.scss'
import PagingButtons from './PagingButtons'
import PDFViewer from './PDFViewer'
import logo from './LogoEsade-ES.svg'
import { css } from '@emotion/react'
import { ClockLoader } from 'react-spinners'
import Download from '@mui/icons-material/Download'
import API from './API'
import Constants from './Constants'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

//import { Document, Page } from 'react-pdf';

function App() {
  // const [contenidoArchivo, setContenidoArchivo] = useState([]);

  const [id, setId] = useState('')
  const [urlArchivo, setUrlArchivo] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState(null)
  const [loading, setLoading] = useState(true)
  const [bodyWidth, setBodyWidth] = useState(0)
  const [error, setError] = useState(false)
  const api = useMemo(() => {
    return new API()
  }, [])

  useEffect(() => {
    const init = async () => {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const pParametro = urlParams.get('id')

      if (pParametro !== null) {
        setId(pParametro)

        try {
          let response = await api.fetchDoc(pParametro)
          buildPdfReader(response)
        } catch (e) {
          setLoading(false)
          setError(true)
        }
      } else {
        setLoading(false)
      }
    }

    init()

    let body = document.getElementsByTagName('body')[0]
    setBodyWidth(body.clientWidth)
    body.style.overflowX = 'hidden'

    body.onresize = changeStoredWidth
  }, [api])

  const buildPdfReader = (response) => {
    let blob = new window.Blob([response.data], {
      type: 'application/pdf',
    })
    //Build a URL from the file
    setUrlArchivo(URL.createObjectURL(blob))

    let body = document.getElementsByTagName('body')[0]
    setBodyWidth(body.clientWidth)

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  const changeStoredWidth = () => {
    let body = document.getElementsByTagName('body')[0]
    body.style.overflowX = 'inherit'
    setBodyWidth(body.clientWidth)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const changePage = useCallback(
    (offset) =>
      setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  )

  const previousPage = useCallback(() => changePage(-1), [changePage])

  const nextPage = useCallback(() => changePage(1), [changePage])

  const downloadFile = () => {
    const link = document.createElement('a')

    // Set link's href to point to the Blob URL
    link.href = urlArchivo
    link.download = id

    // Append link to the body
    document.body.appendChild(link)

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    )

    // Remove link from body
    document.body.removeChild(link)
  }

  const fetch = async () => {
    setLoading(true)
    try {
      let response = await api.fetchDoc(id)
      buildPdfReader(response)
    } catch (e) {
      setLoading(false)
      setError(true)
    }
  }

  return (
    <div className="App">
      <header id="main-header" className="main-header">
        <div className="main-logo">
            <img
              className="logo-img"
              src={logo}
              alt="esade_logo"
              height={80}
              width={150}
              onClick={() => {window.location.reload()}}
            />
        </div>
        <div
          className="diagonal-menu dblock"
          style={{ borderRight: `${bodyWidth}px solid rgb(0, 11, 61)` }}
        ></div>
      </header>

      <section id="main-content">
        <article>
          {!urlArchivo && !loading && !error && (
            <div className="main">
              <label className="ESADEfont">
                Hello! Input the certificate CSV number to verify the document:
              </label>
              <input
                type="text"
                onChange={(ev) => setId(ev.currentTarget.value)}
                placeholder="Certificate CSV number"
                className="csv-search"
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter' || ev.key === 'NumpadEnter') fetch()
                }}
              />
              <input
                type="button"
                value="Verify"
                onClick={() => fetch()}
                className="fetch-button"
              />
            </div>
          )}
          {urlArchivo && !loading && !error && (
            <>
              <div className="title">
                <p>Original document issued by ESADE:</p>
              </div>
              <div className="pdf-viewer">
                <div style={{ display: loading ? 'none' : 'inherit' }}>
                  <PDFViewer
                    file={urlArchivo}
                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                    numPages={numPages}
                    pageNumber={pageNumber}
                  />
                  {numPages > 1 && (
                    <PagingButtons
                      nextPage={nextPage}
                      previousPage={previousPage}
                      numPages={numPages}
                      pageNumber={pageNumber}
                    />
                  )}
                  <button
                    type="button"
                    className="download-button"
                    onClick={downloadFile}
                  >
                    <Download />
                    <span className="download-button --text">
                      Download file
                    </span>
                  </button>
                </div>
              </div>
            </>
          )}
          {error && !loading && (
            <div className="main">
              <label className="ESADEfont">
                <b>Something went wrong :(</b>
              </label>
              <br />
              <label className="ESADEfont">
                 <a href=".">Try again</a>, otherwise contact <a href={`mailto:${Constants.Strings.ErrorContactEmail}`}><em>{Constants.Strings.ErrorContactEmail}</em></a>
              </label>
            </div>
          )}
          <ClockLoader
            color="#000b3d"
            loading={loading}
            css={override}
            size={150}
          />
        </article>
      </section>

      <footer id="main-footer">
        <p>
          &copy; {new Date().getFullYear()}{' '}
          <a href="https://www.esade.edu/">Esade</a>
        </p>
      </footer>
    </div>
  )
}

export default App
