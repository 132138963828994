import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface PDFViewerProps {
    file: string;
    numPages: number;
    pageNumber: number;
    onDocumentLoadSuccess(): void;
}

const PDFViewer = (props: PDFViewerProps) => {
    return (
        <Document
            file={props.file}
            onLoadError={console.error}
            onLoadSuccess={props.onDocumentLoadSuccess}
            renderMode='svg'
        >
            <Page key={`page_${props.pageNumber}`} pageNumber={props.pageNumber} />
        </Document>
    );
};

export default PDFViewer;