import React from 'react';

export interface PagingButtonsProps {
    pageNumber: number;
    numPages: number;
    previousPage(): void;
    nextPage(): void;
}

const PagingButtons = (props: PagingButtonsProps) => {

    return (
        <div className="page-controls">
            <button
                disabled={props.pageNumber <= 1}
                type="button"
                onClick={props.previousPage}
            >
                ‹
            </button>
            <span>
                {props.pageNumber} of {props.numPages}
            </span>
            <button
                disabled={props.pageNumber >= props.numPages}
                type="button"
                onClick={props.nextPage}
            >
                ›
            </button>
        </div>
    );
};

export default PagingButtons;